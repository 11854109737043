<template>
  <v-row>
    <v-col class="pa-0" cols="12">
      <v-toolbar class="px-0 transparent" flat height="45">
        <v-toolbar-title>
          <v-hover v-slot:default="{ hover }">
            <v-btn class="subtitle-1 font-weight-medium" :class="{ 'text--primary': !hover }" :to="{ name: 'ComplianceProcessList' }" color="primary" height="24" text>Cuadraturas</v-btn>
          </v-hover>
          <v-icon class="mx-2" color="fontBody">mdi-chevron-right</v-icon>
          <span class="subtitle-1 text--secondary">Tienda 24 - 2023/11/12</span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center">
            <v-btn class="mr-3" outlined>Exportar registros<v-icon right>mdi-download</v-icon></v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>

    <v-col class="px-0" cols="12">
      <v-card class="mt-4" flat>
        <v-card-title class="pa-0">
          <v-list dense color="transparent">
            <v-list-item>
              <v-list-item-icon class="mx-4">
                <v-icon color="primary" size="24">mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="body-1 fontBody--text font-weight-medium text-truncate">Real Plaza S.R.L.<span class="ml-3 body-2 fontDraw--text">RUC 20547141068</span></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-title>

        <v-divider />

        <v-card-text class="pa-0">
          <v-row align="center" justify="center" no-gutters>
            <!-- information -->
            <v-col class="pa-6">
              <v-list>
                <v-list-item class="py-1">
                  <v-list-item-title><v-icon color="primary" left size="18">mdi-eye-circle</v-icon>Estado</v-list-item-title>
                  <v-list-item-subtitle><v-chip color="deep-orange lighten-4" text-color="deep-orange darken-4" label small>Con incidencias<v-icon right>mdi-bug-outline</v-icon></v-chip></v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-1">
                  <v-list-item-title><v-icon color="primary" left size="18">mdi-database</v-icon>Fuente de la información</v-list-item-title>
                  <v-list-item-subtitle><img :src="require(`@/assets/brands/brand--ncr.svg`)" alt="brand--ncr" height="18"><span class="d-block-inline position-absolute body-1 text--primary">Tiendas / PoS - <span class="text--secondary">NCR</span></span></v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-1">
                  <v-list-item-title><v-icon color="primary" left size="18">mdi-clock-time-three</v-icon>Fecha de inicio de la revisión</v-list-item-title>
                  <v-list-item-subtitle>{{ today | dateTime }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-1">
                  <v-list-item-title><v-icon color="primary" left size="18">mdi-clock-time-three</v-icon>Fecha de término de la revisión</v-list-item-title>
                  <v-list-item-subtitle>{{ today | dateTime }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
            <!-- end information -->

            <v-divider vertical />

            <!-- status -->
            <v-col class="px-6 py-2">
              <v-row>
                <v-col class="text-center">
                  <v-icon color="primary" left size="32">mdi-receipt-text-arrow-left</v-icon>
                  <span class="d-block mt-2 subtitle-2 text--secondary">Total de documentos cargados</span>
                  <span class="d-block mt-3 text-h4 text--primary font-weight-medium">{{ 346862 | number }}</span>
                </v-col>
                <v-divider vertical />
                <v-col class="text-center">
                  <v-icon color="deep-orange darken-4" left size="32">mdi-receipt-text-remove</v-icon>
                  <span class="d-block mt-2 subtitle-2 text--secondary">Total de incidencias</span>
                  <span class="d-block mt-3 text-h4 text--primary font-weight-medium">{{ 1238 | number }}</span>
                </v-col>
              </v-row>
              <v-row class="mt-8">
                <v-col class="py-0 text-center">
                  <v-btn :to="{ name: 'ComplianceDocumentList', params: { id: 'foo' } }" color="deep-orange darken-4">Revisar incidencias (1.238)<v-icon right>mdi-magnify</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- end status -->
          </v-row>

          <v-divider />

          <v-row justify="center" no-gutters>
            <v-col class="pa-6">
              <span class="d-block subtitle-2 text--primary font-weight-medium"><v-icon class="mt-n1" color="primary" left>mdi-database-search</v-icon>Total de incidencias por tipo</span>
              <v-sheet class="mt-4 transparent">
                <v-row align="center" no-gutters>
                  <v-col class="pa-0" cols="7">
                    <chart-bar :height="260" :chart-data="byIssue" />
                  </v-col>
                  <v-col class="pa-0" cols="5">
                    <span class="d-block ml-4 my-1 body-1 text--secondary"><v-icon class="ma-0 pa-0 mr-2" size="18" color="#2962FF" left>mdi-square-rounded</v-icon>No encontrado</span>
                    <span class="d-block ml-4 my-1 body-1 text--secondary"><v-icon class="ma-0 pa-0 mr-2" size="18" color="#4685FC" left>mdi-square-rounded</v-icon>Descuadre en fecha</span>
                    <span class="d-block ml-4 my-1 body-1 text--secondary"><v-icon class="ma-0 pa-0 mr-2" size="18" color="#7AA6F9" left>mdi-square-rounded</v-icon>Descuadre en importe total</span>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>

            <v-divider vertical />

            <v-col class="pa-6">
              <span class="d-block subtitle-2 text--primary font-weight-medium"><v-icon class="mt-n1" color="primary" left>mdi-database-search</v-icon>Total de incidencias según el origen</span>
              <v-sheet class="mt-4 transparent">
                <v-row align="center" no-gutters>
                  <v-col class="pa-0" cols="7">
                    <chart-bar :height="260" :chart-data="bySourceIssue" />
                  </v-col>
                  <v-col class="pa-0 pl-6" cols="5">
                    <span class="d-block my-1 body-1 text--secondary"><v-icon class="ma-0 pa-0 mr-2" size="18" color="primary" left>mdi-square-rounded</v-icon><span class="text--primary font-weight-medium">360</span> incidencias en Fuente</span>
                    <span class="d-block my-1 body-1 text--secondary"><v-icon class="ma-0 pa-0 mr-2" size="18" color="primary" left>mdi-square-rounded</v-icon><span class="text--primary font-weight-medium">453</span> incidencias en Axteroid</span>
                    <span class="d-block my-1 body-1 text--secondary"><v-icon class="ma-0 pa-0 mr-2" size="18" color="primary" left>mdi-square-rounded</v-icon><span class="text--primary font-weight-medium">235</span> incidencias en OSE</span>
                    <span class="d-block my-1 body-1 text--secondary"><v-icon class="ma-0 pa-0 mr-2" size="18" color="primary" left>mdi-square-rounded</v-icon><span class="text--primary font-weight-medium">190</span> incidencias en Sunat</span>
                    <v-btn class="mt-4 mr-3" color="primary" :to="{ name: 'ComplianceTask', query: { process: 'foo' } }" target="_blank">Ver en Task manager<v-icon right>mdi-open-in-new</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import ListViewMixin from '@/mixins/ListViewMixin'
import ChartBar from '@/components/charts/ChartBar'
import ChartDoughnut from '@/components/charts/ChartDoughnut'

export default {
  components: {
    ChartBar,
    ChartDoughnut
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    currentItem: {},
    modalRedirect: false,
    modalErrorInf: false,
    count: 10,
    selectedItem: 0,
    bySourceIssue: {
      labels: ['Fuente', 'Axteroid', 'OSE', 'Sunat'],
      datasets: [
        { data: [360, 453, 235, 190] }
      ]
    },
    byIssue: {
      labels: ['Fuente', 'Axteroid', 'OSE', 'Sunat'],
      datasets: [
        { data: [100, 150, 60, 50] },
        { data: [120, 153, 80, 40] },
        { data: [60, 50, 45, 70] }
      ]
    },
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  methods: {
    getCurrent () {
      //
    },
    handleModalRedirect () {
      this.modalRedirect = false
      this.$router.push({name: 'DocumentsRetrieve', params: {id: 'doc_1H5BlmXOI0B6UevnDi' }}).catch(() => {})
    },
    handleErrorInf (item = {}) {
      this.modalErrorInf = true
    }
  }
}
</script>